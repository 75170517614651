/**
 *  Created by gym on 2018/3/14 上午11:21.
 */

export default [
  {
    id: '@payments-method',
    reducer: () => require('./payments-method-reducer'),
    path: '/payments-method',
    ref: '/',
    onload: () => import('./payments-method-view'),
  },
];
