/**
 *  Created by gym on 2018/3/14 上午11:26.
 */

import { Reducer } from '@ekuaibao/store'

import { catchError } from '@ekuaibao/lib/lib/lib-util'
import key from './key'

const reducer = new Reducer(key.ID, {
  payments: [],
  payOpportunityList: []
})

export default reducer

reducer.handle(key.GET_PAYMENTS_LIST)((state, action) => {
  let payments = action.payload.items
  return { ...state, payments }
})

reducer.handle(key.SAVE_PAYMENT)(
  catchError((state, action) => {
    return { ...state }
  })
)

reducer.handle(key.RECOMPOSE_PAYMENT)(
  catchError((state, action) => {
    return { ...state }
  })
)

reducer.handle(key.GET_OPPORTUNITY_LIST)((state, action) => {
  if (action.error) {
    return { ...state }
  }
  let payOpportunityList = action.payload.items
  return { ...state, payOpportunityList }
})

reducer.handle(key.RESTORE_PAYMENT_TYPE)((state, action) => {
  if (action.error) {
    return state
  }
  return { ...state }
})
