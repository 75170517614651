/**
 *  Created by gym on 2018/3/14 上午11:22.
 */
export const ID = '@payments-method'

export default {
  ID: ID,
  GET_PAYMENTS_LIST: `${ID}/GET_PAYMENTS_LIST`,
  GET_FULLPAYMENTS_LIST: `${ID}/GET_FULLPAYMENTS_LIST`,
  GET_OPPORTUNITY_LIST: `${ID}/GET_OPPORTUNITY_LIST`,
  SAVE_PAYMENT: `${ID}/SAVE_PAYMENT`,
  RECOMPOSE_PAYMENT: `${ID}/RECOMPOSE_PAYMENT`,
  RESTORE_PAYMENT_TYPE: `${ID}/RESTORE_PAYMENT_TYPE`
}
